import { useEffect, useRef, forwardRef } from 'react'
import styled from 'styled-components'
import { ReactComponent as RightArrowDisabled } from '@/svg/right-arrow-disabled.svg'
import { ReactComponent as RightArrowFilled } from '@/svg/right-arrow-filled.svg'
import { TimesCircledIcon } from '@shipt/design-system-icons'
import { type PillInputProps, type WrapperProps } from '@/elements/Inputs/types'
import {
  Body1FontStyles,
  InputContainer,
  HelperMessagesGroup,
  HelperText,
  InputButton,
  InputElement,
} from '@/elements/Inputs/styles'
import { Row } from '@shipt/design-system-layouts'
import { TransparentButtonV2 } from '@/elements/Buttons'

type Props = PillInputProps

export const PillInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      label,
      type,
      onFocus,
      onBlur,
      onChange,
      handleClear,
      onKeyDown,
      onSubmit,
      value,
      error = '',
      name,
      maxLength,
      ariaLabel,
      autoComplete,
      inputMode,
      icon: Icon,
      iconLabel = 'SVG',
      hasSubmitButton = true,
      showError = true,
      autoFocus,
      surface = 'default',
      isGrayBackground = false,
    }: Props,
    ref
  ) => {
    const isDisabled = value?.length !== maxLength
    const buttonLabel = isDisabled ? 'Button disabled' : 'Button enabled'
    // Remove the use of button svg for buttons
    const SubmitButton = !isDisabled ? RightArrowFilled : RightArrowDisabled
    const errorId = `${id}-error`
    const hasError = Boolean(error)
    const componentRef = useRef<HTMLInputElement | null>(null)
    const inputRef = ref || componentRef

    useEffect(() => {
      // the type for 'ref' with forwardRef is RefObject instead of MutableRefObject
      if (typeof inputRef !== 'function' && inputRef !== null && autoFocus) {
        setTimeout(() => {
          inputRef?.current?.focus()
        }, 50)
      }
    }, [autoFocus, inputRef])

    const handleOnClickIcon = () => {
      if (inputRef && 'current' in inputRef) {
        inputRef.current?.focus()
      }
    }
    const inputProps = {
      id,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      'aria-label': ariaLabel,
      value,
      maxLength,
      name,
      autoComplete,
      inputMode,
      ...(error && {
        hasError: true,
        'aria-invalid': true,
        'aria-describedby': errorId,
      }),
    }

    return (
      <InputContainer>
        <ContentWrapper
          hasError={hasError}
          hasImage={Boolean(Icon)}
          surface={surface}
          isGray={isGrayBackground}
        >
          {Icon && <Icon aria-label={iconLabel} onClick={handleOnClickIcon} />}
          <InputBorderWrapper>
            <Input
              {...inputProps}
              placeholder={label}
              type={type}
              ref={inputRef}
            />
          </InputBorderWrapper>
          {Boolean(value) && (
            <Row>
              {handleClear && (
                <ResetButton
                  type="reset"
                  onClick={handleClear}
                  aria-label="reset"
                >
                  <TimesCircledIcon size="sm" />
                </ResetButton>
              )}
              <InputButton
                disabled={isDisabled}
                aria-label={buttonLabel}
                type="submit"
                onClick={onSubmit}
              >
                {hasSubmitButton && <SubmitButton />}
              </InputButton>
            </Row>
          )}
        </ContentWrapper>
        {showError && hasError && (
          <HelperContainer>
            <HelperText
              aria-label={errorId}
              id={errorId}
              hasError
              surface={surface}
            >
              {error}
            </HelperText>
          </HelperContainer>
        )}
      </InputContainer>
    )
  }
)

const Input = styled(InputElement)`
  background-color: inherit;
`

const ContentWrapper = styled.div<WrapperProps & { isGray: boolean }>`
  grid-template-columns: ${({ hasImage }) =>
    hasImage ? 'auto 1fr auto' : '1fr auto'};
  display: grid;
  gap: 8px;
  align-items: center;
  padding: 0 6px 0 16px;
  min-height: 48px;
  border-radius: 64px;
  background: ${({ theme, isGray }) => (isGray ? theme.gray100 : theme.white)};

  &:focus-within {
    background: ${({ theme, isGray }) => isGray && theme.white};
    border: 2px solid;
  }

  border: 1px solid
    ${({ theme, hasError, surface, isGray }) => {
      if (hasError) return surface === 'default' ? theme.red500 : theme.red400
      if (isGray) return theme.gray100
      return theme.plum
    }};
  box-sizing: border-box;
`

const InputBorderWrapper = styled.div`
  ${Body1FontStyles};
  position: relative;
  cursor: text;

  &:focus-within label {
    color: ${({ theme }) => theme.gray400};
  }
`
const ResetButton = styled(TransparentButtonV2)`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
`

const HelperContainer = styled(HelperMessagesGroup)`
  padding-left: 1rem;
`
